export const data = {
  bio: (
    <>
      <p>
          Hello friends! My name is Zach Kaigler, and I’m a Brooklyn based software engineer
          with a passion for beautiful design and years of experience pushing production
          code.
      </p>
      <p>
          I'm currently working with <a href='https://capsule.video'>Capsule</a> to develop AI powered
          tools built to help democratize video production for everyone, and so at the moment I'm not
          looking for new opportunities. I am, however, always up for talking shop! Please feel free to
          reach out with any thoughts, questions, or Magic: The Gathering deck ideas.
      </p>
    </>
  )
};
